import "./apply-tile-small.scss";

import React from "react";
import classNames from "classnames";

const ApplyTileSmall = ({ number, title, desc, color, noarrow }) => {
  return (
    <div
      className={classNames(`apply-tile-small`, {
        [`apply-tile-small--no-arrow`]: noarrow,
      })}
      style={{ backgroundColor: color }}
    >
      <div className="apply-tile-small__number" style={{ color: color }}>
        {number}
      </div>
      <div className="apply-tile-small__title">{title}</div>
      <div className="apply-tile-small__text">{desc}</div>
    </div>
  );
};

export default ApplyTileSmall;
