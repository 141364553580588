import "styles/pages/page-how-to-apply.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";
import ProjectBanner from "components/ProjectBanner";
import { Link } from "gatsby";
import ApplyTileSmall from "../components/ApplyTileSmall";
import ApplyTileBig from "../components/ApplyTileBig";

const HowToApplyPage = ({ pageContext }) => {
   const seo = pageContext.seo;
   const page = pageContext.data;

   return (
      <Layout>
         <Seo
            title={seo.title ? seo.title : pageContext.title}
            description={seo.metaDesc}
         />
         <Breadcrumbs currentLocationLabel="Jak złożyć wniosek" />
         <section className="apply-page">
            <div className="container">
               <SectionTitle title="Jak złożyć wniosek" />
               <div className="apply-page__text">
                  Chcesz wiedzieć więcej – przeczytaj{" "}
                  <Link to="/regulamin-glosowania">Regulamin</Link>, a potem{" "}
                  <Link to="/kontakt">skontaktuj się z nami</Link>.
               </div>
               <h2 className="apply-page__title">{page.findTitle}</h2>
               <div className="row apply-page__row">
                  {page.findTiles.map((item, index) => (
                     <div className="col-xl-2 col-md-4" key={index}>
                        <ApplyTileSmall
                           title={item.title}
                           desc={item.subtitle}
                           number={index + 1}
                           color={item.color}
                        />
                     </div>
                  ))}
               </div>
               <div className="apply-page__banner-wrapper">
                  <ProjectBanner
                     title="Zgłoś swój projekt"
                     link="https://www.witkac.pl/Account/Login"
                     btnText="Przejdź do generatora"
                  />
               </div>
               <div className="row justify-content-center">
                  {page.projectTiles.map((item, index) => (
                     <div className="col-xl-6" key={index}>
                        <ApplyTileBig
                           title={item.title}
                           desc={item.subtitle}
                           icon={item.icon?.sourceUrl}
                           number={index + 1}
                           color={item.color}
                        />
                     </div>
                  ))}
               </div>
            </div>
         </section>
      </Layout>
   );
};

export default HowToApplyPage;
