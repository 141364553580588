import "./apply-tile-big.scss";

import React from "react";

const ApplyTileBig = ({
   title,
   desc,
   icon,
   number,
   color,
   rotateArrow,
   noarrow,
}) => {
   return (
      <div className="apply-tile-big" style={{ backgroundColor: color }}>
         {!noarrow && (
            <div
               className="apply-tile-big__arrow"
               style={{ transform: `rotate(${rotateArrow}deg)` }}
            />
         )}
         <div className="apply-tile-big__number" style={{ color: color }}>
            {number}
         </div>
         <img src={icon} alt="Ikona" className="apply-tile-big__icon" />
         <div className="apply-tile-big__title">{title}</div>
         <div
            className="apply-tile-big__desc"
            dangerouslySetInnerHTML={{ __html: desc }}
         />
      </div>
   );
};

export default ApplyTileBig;
